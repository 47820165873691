var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reputation"},[_c('a-modal',{attrs:{"title":_vm.$t('public_reputation_title'),"footer":null},model:{value:(_vm.isChangeKarmaModalOpen),callback:function ($$v) {_vm.isChangeKarmaModalOpen=$$v},expression:"isChangeKarmaModalOpen"}},[(_vm.activeUserRecord)?[(_vm.activeUserRecord.karma !== 0)?_c('div',{staticClass:"flex items-center"},[_c('span',[_c('a-icon',{staticClass:"text-2xl",staticStyle:{"color":"rgba(var(--a-success), 1)"},attrs:{"type":"like"}}),_vm._v(" "+_vm._s(_vm.$t('reputation_table_hint_karma_positive'))+" ")],1),_c('b',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm.activeUserRecord.karma)+" ")])]):_vm._e(),(_vm.activeUserRecord.anti_karma !== 0)?_c('div',{staticClass:"flex mt-2 items-center"},[_c('span',[_c('a-icon',{staticClass:"text-2xl",staticStyle:{"color":"rgba(var(--a-danger), 1)"},attrs:{"type":"dislike"}}),_vm._v(" "+_vm._s(_vm.$t('reputation_table_hint_karma_negative'))+" ")],1),_c('b',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm.activeUserRecord.anti_karma)+" ")])]):_vm._e()]:_vm._e()],2),_c('table-layout',{attrs:{"title":_vm.$t('public_reputation_title'),"title-target":_vm.chat_title,"gentime":_vm.gentime,"stat-title":_vm.$t('public_reputation_subtitle')}},[_c('div',{staticClass:"w-full flex justify-end"},[_c('a-input-search',{staticClass:"md:w-1/4 w-full mb-4",model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}})],1),_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-table',{key:_vm.tableTemplate,attrs:{"data-source":_vm.computedReputationList,"pagination":{
            size: _vm.isMobile() ? 'small' : 'large',
            total: _vm.computedReputationList.length,
            showSizeChanger: true,
          },"row-key":function (record) { return record.name + ':' + record.id; },"scroll":{
            x: true
          },"bordered":""}},[_c('a-table-column',{key:"index",attrs:{"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var index = ref.index;
          var prev_index = ref.prev_index;
return _c('p',{staticClass:"mb-0 flex items-center"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(index)+" ")]),(index !== prev_index)?_c('span',[(index < prev_index)?_c('a-icon',{staticClass:"text-success",staticStyle:{"font-size":"10px"},attrs:{"type":"double-right","rotate":-90}}):_c('a-icon',{staticClass:"text-danger",staticStyle:{"font-size":"10px"},attrs:{"type":"double-right","rotate":90}})],1):_vm._e()])}}])},[_c('a-icon',{attrs:{"slot":"title","type":"number"},slot:"title"})],1),_c('a-table-column',{key:"user",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var name = ref.name;
          var login = ref.login;
          var avatar = ref.avatar;
return _c('user-main-info',{attrs:{"name":name,"login":login,"avatar":avatar}})}}])},[_c('a-icon',{attrs:{"slot":"title","type":"user"},slot:"title"})],1),(_vm.columnsVisibility.reputation)?_c('a-table-column',{key:"karma",attrs:{"align":"center","sort-directions":['descend', 'ascend'],"sorter":function (a, b) { return (a.karma - a.anti_karma) - (b.karma - b.anti_karma); }},scopedSlots:_vm._u([{key:"default",fn:function(record){return _c('span',{},[_vm._v(" "+_vm._s(record.karma - record.anti_karma)+" "),_c('sup',[_c('a',{on:{"click":function($event){$event.preventDefault();_vm.activeUserRecord = record; _vm.isChangeKarmaModalOpen = true}}},[_c('a-icon',{attrs:{"type":"question-circle"}})],1)])])}}],null,false,3389852804)},[_c('icon',{staticClass:"align-middle",attrs:{"slot":"title","icon":"stars"},slot:"title"})],1):_vm._e(),(_vm.columnsVisibility.rank)?_c('a-table-column',{key:"rank",attrs:{"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var rank_title = ref.rank_title;
return _c('span',{},[_vm._v(" "+_vm._s(rank_title)+" ")])}}],null,false,3923743878)},[_c('i',{staticClass:"material-icons",attrs:{"slot":"title"},slot:"title"},[_vm._v(" military_tech ")])]):_vm._e(),(_vm.columnsVisibility.xp)?_c('a-table-column',{key:"xp",attrs:{"align":"center","sorter":function (a, b) { return a.xp - b.xp; },"sort-directions":['descend', 'ascend']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var xp = ref.xp;
return _c('span',{},[_vm._v(" "+_vm._s(xp ? xp : '-')+" ")])}}],null,false,1463301574)},[_c('span',{staticClass:"font-bold",attrs:{"slot":"title"},slot:"title"},[_c('i',[_vm._v("XP")])])]):_vm._e(),(_vm.columnsVisibility.ap)?_c('a-table-column',{key:"ap",attrs:{"align":"center","sort-directions":['descend', 'ascend'],"sorter":function (a, b) { return a.action_point - b.action_point; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var action_point = ref.action_point;
return _c('span',{},[_vm._v(" "+_vm._s(action_point ? action_point : '-')+" ")])}}],null,false,1534754371)},[_c('span',{staticClass:"font-bold",attrs:{"slot":"title"},slot:"title"},[_c('i',[_vm._v("AP")])])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }